
/*global console, hex_md5 */
(function (app, $, document, hexMD5) {

	var trackerData = (app && app.trackerData) || {},
		$document = $(document),
		pick = app.util.pick,
		regexEmail1 = app.validator.regex.email,
		/** @consts for shortcats*/
		PRODUCT_VARIANTS = ".js-gtm_product_variants_info",
		body = "body",
		click = "click",
		SUBMIT = "submit",
		SUBSCRIPTION = "newsletterSubscription",
		EMARSYS_POPUP = 'EMARSYS POPUP',
		notify_me_pop_up = "NOTIFY ME POP UP",
		serializeObject = app.util.form2Object,
		$focusRetriever,
		// mapping: soc button class -> soc network required name
		socialNetworkNamesMap = {
			"addthis_button_facebook_like"			: "Facebook_like",
			"addthis_button_facebook"				: "Facebook_like",
			"facebook"								: "Facebook_like",
			"addthis_button_tweet"					: "Tweet",
			"addthis_button_twitter"				: "Tweet",
			"twitter"								: "Tweet",
			"addthis_button_pinterest_pinit"		: "Pinterest_pin-it",
			"addthis_button_pinterest"				: "Pinterest_pin-it",
			"pin-it-button"							: "Pinterest_pin-it",
			"pinterest"								: "Pinterest_pin-it",
			"addthis_button_google_plusone"			: "Google+_+1",
			"addthis_button_google_plusone_share" 	: "Google+_+1",
			"addthis_button_google"					: "Google+_+1",
			"googleplus"							: "Google+_+1",
			"addthis_button_sinaweibo"				: "Sinaweibo",
			"addthis_button_tumblr"					: "Tumblr"
		},		/*
		*   The only way to handle click on social button in IFRAME is:
		*	- handle iframe hover event and save iframe identity
		*	- consider top.blur event as a click inside the IFRAME
		*/
		iframeHoverController = (function ($) {
			var _socialButtonMatch = "a[class^='addthis_button'] iframe",
				_toReturn = { hoverHref: null };

			$document.ready(function () {
				$(body).on("mouseenter", _socialButtonMatch, function (e) {
					_toReturn.hoverHref = $(e.target).parents("a");
					$focusRetriever.focus();
				});

				$(body).on("mouseleave", _socialButtonMatch, function () {
					_toReturn.hoverHref = null;
					$focusRetriever.focus();
				});
			});

			return _toReturn;
		})($),
		networkNameMap = {
			"www.facebook.com" : "Facebook",
			"instagram.com" : "instagram",
			"twitter.com" : "Twitter",
			"www.pinterest.com" : "Pinterest"
		},
		customizedProductType = "customize." + app.resources.CONF.VARIANTS.type + "." + trackerData.productMasterID || "";

	function getCurrentProduct(id) {
		var $pPopUpInfo = $(".js-pdp_main").find(PRODUCT_VARIANTS),
			$pProductInfo = $("head").children(PRODUCT_VARIANTS),
			$pWishListInfo,
			json = "",
			product;

		id = id || "default";

		if ($pPopUpInfo.length) {
			json = JSON.parse($pPopUpInfo.html());
			$.each(json, function (i, k) {
				if (id === k.productSku) {
					product = k;
				}
			});
		} else if ($pProductInfo.length) {
			json = JSON.parse($pProductInfo.html());
			$.each(json, function (i, k) {
				if (id === k.productSku) {
					product = k;
				}
			});
		} else if (trackerData.wishlist) {
			$.each(trackerData.wishlist, function (i, k) {
				if (id === k.inID) {
					product = k;
				}
			});
		}

		if (!product && ($pWishListInfo = $(".js-header_wishlist_tracker_data")) && $pWishListInfo.length) {
			json = JSON.parse($pWishListInfo.html());
			$.each(json, function (i, k) {
				if (id === k.inID) {
					product = k;
				}
			});
		}

		return product || trackerData;
	}


	// hack to prevent stay iframe always in focus
	$(body).append("<div style='position:fixed; top:0; left:0; overflow:hidden;'>" +
			"<input style='position:absolute; left:-300px;' type='text' aria-label='focus retriever' value='' id='focus_retriever' /></div>");
	$focusRetriever = $("#focus_retriever");

	function onClickSocialButton(push) {
		$document.on("mouseup touchend", "a[class^='addthis_button_'], div.pinterest a, a[class='social-share-button']", function () {
			var socialNetwork = socialNetworkNamesMap[ $(this).data('share') || $(this).attr("class").split(" ")[0]],
				m = /pid\=([\d\w]+)/ig.exec($(this).attr("addthis:url")) ||
				/pid\=([\d\w]+)/ig.exec($(this).attr("href")),
				product = getCurrentProduct(m && m[1]);

			push({
				"event": "socialEvent",
				"socialNetwork": socialNetwork || "",
				"productName": product.productName || "",
				"productPrice": product.productPrice || ""
			});
		});
	}

	function clickOnTumblr(push) { // not in IFRAME
		$(body).on(click, ".addthis_button_tumblr", function () {
			if (trackerData) {
				push({
					"event": "socialEvent",
					"socialNetwork": "Tumblr_like",
					"productName": trackerData.productName || "",
					"productPrice": trackerData.productPrice || ""
				});
			}
		});
	}

	/**
	 * Handles click event on product size selection and triggers a tracking event for size change.
	 * @param {function} push - A function that accepts an object and pushes it to the data layer for tracking.
	 */
	function selectSizeClick(push) {
		$document.on('changeVariation.changed', () => {
			const productIdContainer = trackerData.productSku = document.querySelector('.js-product_id');

			if (trackerData && productIdContainer) {
				trackerData.productSku = productIdContainer.value;

				push({
					event: 'sizeChanged',
					productSku: trackerData.productSku,
				});
			}
		});
	}

	function footerLinks(push) {
		$(".js-footer_container a").on(click, function () {
			var $this = $(this);
			if ($this.prop("href") && $this.prop("href").indexOf(window.location.hostname) === -1 &&
					$this.prop("href").indexOf("javascript") === -1) {

				var siteName = $this.prop("title"),
					networkName = $this.prop("title"),
					m = /http[s]?\:\/\/([\w\.]+)/ig.exec($this.prop("href")),
					n = /(\w+)$/ig.exec(networkName);
				if (m && m[1]) {
					siteName = m[1];
				}
				if (n && n[1]) {
					networkName = n[1];
				}
				if (!networkName && networkNameMap[siteName]) {
					networkName = networkNameMap[siteName];
				}

				push({
					"event" : "externalLinkClick",
					"socialNetwork": networkName,
					"externalWebsiteName": siteName
				});
			}
		});
	}

	function onQuickView(push) {
		$document.on("quickview.opened", function () {
			var product = getCurrentProduct();
			push({
				"productName" : product.productName,
				"productCategory" : product.productCategory,
				"productSubcategory" : product.productSubcategory,
				"productSku" : product.productSkuShadow,
				"productPrice" : product.productPrice,
				"ecommerce" : {
					"detail" : {
						"actionField" : {"list": product.productName + " Quick View"},
						"products" : [{
							"id" : product.productSkuShadow,
							"creative" : "quickview"
						}],
						"ecommerceStatus" : "Viewer"
					}
				}
			});
		});
	}

	function sendTofriend(push) {
		$document.on("sendtofriend.send", function (e, form) {
			var $form = $(form);
			if ($form.length) {
				var formObject = serializeObject($form),
					m = /pid\=([\d\w]+)/ig.exec(formObject["sendtofriend_messagelink"]),
					product = getCurrentProduct(m && m[1]);
				push({
					"event" : "socialEvent",
					"socialNetwork" : "Send-to-a-friend",
					"productName" : product.productName || "",
					"productPrice" : product.productPrice || 0,
					"emailId" : hexMD5($.trim(formObject.sendtofriend_friendsemail)),
					"newsletterSubsription" : trackerData.userInfo &&
						trackerData.userInfo.newsletterSubscription
				});
			}
		});

		$(body).on(click, "#sendBtn", function () {
			$document.trigger("sendtofriend.send");
		});
	}

	function notifyMe(push) {
		$document.on("notifyme.send", function (e, form) {
			var $form = $(form);
			if ($form.length) {
				var formObject = serializeObject($form),
					product = getCurrentProduct();
				push({
					"event" : "socialEvent",
					"socialNetwork" : "Notify-me",
					"productName" : product.productName || "",
					"productPrice" : product.productPrice || 0,
					"emailId" : hexMD5($.trim(formObject.notifyme_email))
				});
				$(document).trigger("newsletter.subscribed", [$.trim(formObject.notifyme_email), notify_me_pop_up]);
			}
		});
	}

	function searchResult(push) {
		if (trackerData.searchKeyword) {
			push({
				"event" : "searchData",
				"searchKeyword" : trackerData.searchKeyword,
				"searchCategory" : trackerData.searchCategory,
				"numberResults" : trackerData.numberResults
			});
		}
	}

	function searchStoreLocator(push) {
		$document.on(click, ".storelocator .zipsearchactions a.button", function () {
			var searchCode = $("#dwfrm_storelocator_postalCode").first().val();
			if ($.trim(searchCode) !== "") {
				push({
					"event" : "searchData",
					"searchKeyword" : searchCode,
					"searchCategory" : "store locator",
					"numberResults" : ""
				});
			}
		});

		$document.on("storelocator.search", function (e, data) {
			if (data) {
				var $data = $(data),
					$input =  $("#dwfrm_storelocator_postalCode").first(),
					count = $data.last().find("h2").first().data("storescount") || 0,
					searchCode = ($input.val() === $input.data("placeholder") ? "" : $input.val());

				searchCode += " " + $("#dwfrm_storelocator_address_countries_country").first().val();
				push({
					"event" : "searchData",
					"searchKeyword" : searchCode || "",
					"searchCategory" : "store locator",
					"numberResults" : count
				});
			}
		});
	}

	//Product pages & Quick shop. Click on add to wishlist.
	function addToWishlist(push) {

		$document.on(click, ".carttable .addtowishlist", function () {
			var m = /pid\=([\d\w]+)/ig.exec($(this).prop("href"));
			if (m && m[1]) {
				$.each(trackerData.products, function (i, k) {
					if (m[1] === k.productSku) {
						push({
							"event" : "wishlistAdd",
							"productName" : k.productName || "",
							"productPrice" : k.productPrice || 0
						});
					}
				});
			}
		});

		$document.on("wishlist.added", function () {

			var product = getCurrentProduct($(".js-product_number > span").html());

			push({
				"event" : "wishlistAdd",
				"productName" : product.productName || "",
				"productPrice" : product.productPrice || 0
			});
		});
	}

	function onClickCheckout(push) {
		$document.on(click, "[data-show='.js-checkout_login_container']", function () {
			push({
				"event": "virtualPageview",
				"page": trackerData.URI +
				(trackerData.URI.indexOf("?") !== -1 ? "&" : "?") +
				"checkout=" + trackerData.checkoutStep
			});
		});
	}

	function onLoadCheckout(push){

		var products = {
				"event" : "checkout",
				"ecommerce" : {
					"checkout" : {
						"actionField": {
							"step" : trackerData.checkoutStep
						},
						"products" : []//LIST ALL OTHER PRODUCTS shown on page in this array
					}
				}
			};

		$.each(trackerData.products, function (i, product) {
			products.ecommerce.checkout.products.push({
				id: product.productMasterID,
				name: product.productName,
				price: product.productPrice,
				brand: product.productBrand,
				category: product.productPrimaryCategory,
				variant: product.productColor,
				quantity: trackerData.ecommerce.impressions[i].quantity,
				dimension50: product.productVariationID
			});
			push(products);
		});

		var options = {
				"event" : "checkoutOption",
				"ecommerce" : {
					"checkout_option" : {
						"actionField": {
							"step" : trackerData.checkoutStep
						}
					}
				}
			};

			if ( trackerData.checkoutStep != 1 ) {
				options.ecommerce.checkout_option.actionField.option = $("input[name$=paymentMethods_selectedPaymentMethodID]:checked").attr("value") || trackerData.selectedPaymentID;
			}

			push(options);
	}

	function addToCart(push, product) {
		var addToBagData = {
			"event": "addToBag",
			"addToBagType": "Product page",
			"bagAmount": (parseFloat(product.productPrice || 0) * parseInt(product.quantity, 10)).toFixed(2),
			"productName": product.productName,
			"productSubcategory": product.productSubcategory,
			"productPrice": product.productPrice
		};

		var addToCartData = {
			event: 'addToCart',
			ecommerce: {
				currencyCode: trackerData.currencyCode,
				add: {
					actionField: {
						list: product.trackingOrigin
					},
					products: [{
						id: product.productMasterID,
						quantity: parseInt(product.quantity),
						name: product.productName,
						price: product.productPrice,
						brand: product.productBrand,
						category: product.productPrimaryCategory,
						variant: product.productColor,
						dimension9: product.productDimension9,
						dimension10: product.productDimension10,
						dimension50: product.productDimension50
					}]
				}
			}
		};

		var activeTabId = localStorage.getItem('activetabid');

		if (activeTabId) {
			addToCartData.ecommerce.activeTabId = activeTabId;
			addToBagData.activeTabId = activeTabId;
		}

		push(addToBagData);
		push(addToCartData);
	}

	function onAddToCart(push) {
		$document.on('tracking.cartItemAdded', function(e, trackingOrigin) {
			trackerData = (app && app.trackerData) || {};
			if (trackerData.cartInfo && trackerData.cartInfo.cartProducts) {
				var lastItemIndex = trackerData.cartInfo.cartProducts.length - 1;
				var trackerDataProduct = trackerData.cartInfo.cartProducts[lastItemIndex];
				var GTMProductObj = {
					productMasterID: trackerDataProduct.masterId,
					productName: trackerDataProduct.name,
					productPrice: trackerDataProduct.price,
					productBrand: trackerDataProduct.brand,
					productSubcategory: trackerDataProduct.category,
					productPrimaryCategory: trackerDataProduct.primaryCategory,
					productColor: trackerDataProduct.color,
					productDimension9: trackerDataProduct.dimension9,
					productDimension10: trackerDataProduct.dimension10,
					productDimension50: trackerDataProduct.dimension50,
					quantity: trackerDataProduct.quantity,
					trackingOrigin: trackerData.productClickOrigin
				};
				addToCart(push, GTMProductObj);
			}
		});
	}

	function onProductsGridView(push) {
		$document.on('tracking.productsgridview', function() {
			var catID = trackerData.isCategorySearch ? trackerData.catID : 'search-results';
			var selector = '.js-search_result-content .js-product_tile, .js-homepage-main .js-product_tile';

			productListHandler(push, selector, catID);
		});
	}

	function registerUser(push) {
		if (window.location.href.indexOf("registration=true") !== -1 &&
				trackerData.userInfo && trackerData.userInfo.accountCustomerId) {
			push({
				"event": "accountCreation",
				"accountCustomerId": trackerData.userInfo.accountCustomerId,
				"visitorGender": trackerData.userInfo.gender,
				"accountType": trackerData.accountType,
				"newsletterSubsription": trackerData.userInfo.newsletterSubscription
			});
		}
	}
	function newsletterSubscriptionFast(push) {
		$document.on("newsletter.subscribed", function (event, email, source) {
			var source = typeof source !== 'undefined' ? source : null;

			if (!email && trackerData.userInfo) {
				email = trackerData.userInfo._email;
			}
			push({
				"event" : SUBSCRIPTION,
				"emailId" : hexMD5(email),
				'nlSection' : source
			});
		});
		$document.on(SUBMIT, ".js-newsletter_unsubscribed_form", function () {
			push({
				"event" : SUBSCRIPTION,
				"emailId" : hexMD5(trackerData.userInfo._email)
			});
		});
		$document.on(SUBMIT, ".js-newsletter_subscription_form", function (event) {
			var formObj = serializeObject($(this));
			if (formObj.newsletter_detailed_email) {
				push({
					"event" : SUBSCRIPTION,
					"emailId" : hexMD5(formObj.newsletter_detailed_email)
				});
			}
		});

		emarsysPopupSubscription(push);
	}

	const emarsysPopupSubscription = (push) => {
		const transactionCallback = ({ emailHash: emailId }) => {
			if (!emailId) {
				return;
			}

			const event = SUBSCRIPTION;
			const nlSection = EMARSYS_POPUP;

			push({ event, nlSection, emailId });
		};

		window.addEventListener('load', () => {
			if (!window.Scarab) {
				return;
			}

			window.Scarab.addTransactionListener(transactionCallback);
		});
	};

	function contactForm(push) {
		$document.on("submit", ".js-customer_service .js-contactus_form", function () {
			var form = serializeObject($(this));
			if (regexEmail1.test($.trim(form.contactus_email)) &&
					typeof form.contactus_myquestion == "string") {

				form.contactus_myquestion =
					form.contactus_myquestion.replace("forms.contactus.myquestion.value.", "");

				push({
					"event" : "contactForm",
					"requestType" : form.contactus_myquestion
				});
			}
		});
	}

	function onClickTab(push) {
		$document.on("mouseup touchend", ".js-product_tabs ul a", function () {
			push({
				"event" : "tabEvent",
				"tabTitle" : (this.innerHTML + "").replace(/&amp;/g, "&"),
				"productName" : trackerData && trackerData.productName
			});
		});
	}

	function setListInProductPageObj() {
		if (window.dataLayer) {
			var index = window.dataLayer.findIndex((elem) => elem.pageType === 'ProductPage');

			if (index !== -1) {
				var searchParams = app.product.getSearchParamsFromStorage();
				var searchParamsObj = app.util.getQueryStringParams(searchParams);

				window.dataLayer[index].ecommerce.detail.actionField.list = searchParamsObj.cgid;
			}
		}
	}

	/*
	 * TODO:PRCONF:V1: check all onConfigurator*** functions:
	 * - check / clarify 'label' attribute of each event with empty values (we should fill they, but info do not exists in TSD)
	 * - check all dummy selectors "js-dummy" and place correct ones
	 * - check other TODO:PRCONF:V1 in this file
	 * */

	function onConfiguratorStartDesign(push) {
		$document.on("click", ".js-product-configurator-step-2 .js-start-2-active", function () {
			push({
				"event" : "custEvent",
				"action" : "productSize",
				"category" : customizedProductType,
				"label" : trackerData && trackerData.productSize || ""
			});
			push({
				"event" : "custEvent",
				"action" : "productFabric",
				"category" : customizedProductType,
				"label" : trackerData && trackerData.productFabric || ""
			});
			push({
				"event" : "custEvent",
				"action" : "productColor",
				"category" : customizedProductType,
				"label" : trackerData && trackerData.productColor || ""
			});
		});
	}

	function onConfiguratorApplyElement(push) {
		$document.on("patchAdded", function (e, type, patchId) {
			push({
				"event" : "custEvent",
				"action" : "elementApply",
				"category" : customizedProductType,
				"label" : type,
				"labelID" : patchId
			});
		});
	}

	function onConfiguratorClose(push) {
		$document.on("click", ".js-configurator-close", function () {
			push({
				"event" : "custEvent",
				"action" : "close",
				"category" : customizedProductType,
				"label" : ""
			});
		});
	}

	function onConfiguratorCloseConfirmed(push) {
		$document.on("click", ".js-popup-button-confirm[data-type='close']", function () {
			push({
				"event" : "custEvent",
				"action" : "closeCustomizator",
				"category" : customizedProductType,
				"label" : ""
			});
		});
	}

	function onConfiguratorChangeSide(push) {
		$document.on("click", ".js-configurator-item-element", function () {
			var itemSide = /left/gm.test($(this).data("type")) ? "left" : "right";
			push({
				"event" : "custEvent",
				"action" : "changeSide",
				"category" : customizedProductType,
				"label" : itemSide
			});
		});
	}

	function onConfiguratorRestart(push) {
		$document.on("click", ".js-popup-button-confirm[data-type='reset']", function () {
			push({
				"event" : "custEvent",
				"action" : "restartCustomization",
				"category" : customizedProductType,
				"label" : ""
			});
		});
	}

	function onConfiguratorSave(push) {
		$document.on("click", ".js-configurator-controllers .js-product-configurator-save", function () {
			push({
				"event" : "custEvent",
				"action" : "saveCustomization",
				"category" : customizedProductType,
				"label" : ""
			});
		});
	}

	function onRemoveFormCart(push) {
		$("button[name$='_deleteProduct']").on(click, function () {
			var id = /\_i(\d+?)\_deleteProduct$/ig.exec($(this).attr("name"));

			if (id && id[1] && trackerData &&
					trackerData.ecommerce &&
					trackerData.ecommerce.impressions &&
					trackerData.ecommerce.impressions[id[1]]
				) {
				var product = trackerData.products[id[1]];
				push({
					"event" : "removeFromCart",
					"ecommerce" : {
						"remove" : {
							"products" : [{
								id: product.productMasterID,
								quantity: trackerData.ecommerce.impressions[id[1]].quantity,
								name: product.productName,
								price: product.productPrice,
								brand: product.productBrand,
								category: product.productSubcategory,
								variant: product.productColor,
								dimension50: product.productVariationID
							}]
						}
					}
				});
			}
		});
	}

	function onReturns(push) {
		if (trackerData.returnsConfirm) {
			push({
				"event" : "orderReturned",
				"RANumber" : trackerData.RANumber,
				"OrderID" : trackerData.OrderID,
				"PageType" : "ReturnConfirmation",
				"amountRefund" : trackerData.amountRefund,
				"currency" : trackerData.currency,
				"products" : trackerData.products
			});
		}
	}

	$.removeCookie('productClickOrigin', {path: '/'});

	function onProductTileClick() {
		$document.on('click', '.js-product_tile a, .js-tile-details', function(event) {
			var selector = '.js-product_tile';
			var $tile = $(event.target).closest(selector);

			if ($tile.length === 0) {
				selector = '.js-product';
				$tile = $(event.target).closest(selector);
			}

			var origin = getTileOrigin($tile);

			if (origin) {
				$.cookie('productClickOrigin', origin, { path: '/' });
			}

			var pid = $tile.data('itemid') || $tile.data('pid');

			if ($tile.data('product-name') && pid) {
				eventData = {
					'event': 'productClick',
					'ecommerce': {
						'click': {
							'actionField': {'list': origin},
							'products': [{
								'name': typeof $tile.data('product-name') != 'undefined' ? $tile.data('product-name') + '' : '', // custom.title attribute in default locale
								'id': pid,
								'price': $tile.data('price'),
								'brand': $tile.data('brand'),
								'category': $tile.data('category'),
								'variant': $tile.data('variant'), // custom.colorDescription field in default locale
								'position': +$(selector).index($tile) + 1,
								'dimension9' : $tile.data('dimension9'),
								'dimension10' : $tile.data('dimension10')
							}]
						}
					},
				};

				app.gtm.pushEvent(eventData);
			}
		});
	}

	function getTileOrigin($target) {
		var origin;

		if ($target.closest('.js-search_result-content').length) {
			origin = app.constants.CURRENT_CATEGORY_ID ? app.constants.CURRENT_CATEGORY_ID : null;
		} else if ($target.closest('.js-last-visited-wrapper').length) {
			origin = 'recently-viewed';
		} else if ($target.closest('.js-you-may-also-like').length) {
			origin = 'you-may-also-like';
		} else if ($target.closest('.js-style-it-with').length) {
			origin = 'wear-this-with';
		} else if ($target.closest('.js-shop-the-look-component').length) {
			origin = 'shop-the-look';
		} else if ($target.closest('.js-product-color-variations-wrapper').length) {
			origin = 'more-colors';
		} else if ($target.closest('.js-recommendations-type3').length) {
			origin = 'type-3';
		} else { // fallback for recommendations block on no search result page, empty cart, empty wishlist etc
			origin = 'you-may-also-like';
		}

		return origin;
	}

	function onProductListLoad(push) {
		var catID = trackerData.isCategorySearch ? trackerData.catID : 'search-results';
		var selector = '.js-search_result-content .js-product_tile, .js-homepage-main .js-product_tile';
		$document.ready(function(){
			productListHandler(push, selector, catID);
		});
		$document.on('grid-update refinements-update', function(){
			productListHandler(push, selector, catID);
		});
	}

	function onType3Load(push) {
		var catID = 'type-3';
		var selector = '.js-recommendations-type3 .js-product_tile';

		$document.on('recommendations.loaded', function() {
			productListHandler(push, selector, catID);
		});
	}

	function onShopLookLoad(push) {
		var catID = 'shop-the-look';
		var selector = '.js-shop-the-look-component .js-product_tile';

		$document.ready(function() {
			productListHandler(push, selector, catID);
		});
		$document.on('recommendations.loaded', function() {
			productListHandler(push, selector, catID);
		});
	}

	function onRecommendationsLoad(push) {
		var catID = 'you-may-also-like';
		var selector = '.js-you-may-also-like .js-product_tile';

		$document.on('recommendations.loaded', function() {
			productListHandler(push, selector, catID);
		});
	}

	function onWearThisWithLoad(push) {
		if (!app.configs.gtm.enableWearThisWithTracking || $document.find('.js-product-recommender').length < 1) {
			return;
		}

		var catID = 'wear-this-with';

		$document.ready(function() {
			$.getJSON(
				app.urls.getProductRecommendations,
				{
					pid: app.page?.currentProduct?.masterID,
					type: 6
				},
				function (data) {
					var products = data?.recommendations?.map(function (rec) {
						return rec.product;
					});

					if (products.length > 0) {
						productListHandler(push, null, catID, products);
					}
				});
		})
	}

	function onRecentlyViewedLoad(push) {
		var catID = 'recently-viewed';
		var selector = '.js-last_visited .js-product_tile';

		$document.ready(function(){
			productListHandler(push, selector, catID);
		});
		$document.on('recommendations.loaded', function() {
			productListHandler(push, selector, catID);
		});
	}

	function onMoreColorLoad(push) {
		var catID = 'more-colors';
		var selector = '.js-product-color-variations-wrapper .js-product_tile';

		$('.js-product-color-variations-wrapper').find('.js-owl_carousel').on('initialized.owl.carousel', function() {
			productListHandler(push, selector, catID);
		});
	}

	function productListHandler(push, selector, catID, products) {
		var impressions = products
			? getImpressionsByArray(products, catID)
			: getImpressionsBySelector(selector, catID);

		if (impressions.length) {
			var data = {
				'event': 'productListLoad',
				'ecommerce': {
					'currencyCode': trackerData.currencyCode,
					'impressions': impressions
				},
			};

			var activeTabId = localStorage.getItem('activetabid');

			if (activeTabId) {
				data.ecommerce.activeTabId = activeTabId;
			}

			push(data);
		}
	}

	function getImpressionsBySelector(selector, catID) {
		var $tiles = $(selector);
		var impressions = [];

		for (var i = 0, l = $tiles.length; i < l; i++) {
			var $tile = $($tiles[i]);

			if ($tile.data('gtm-processed') || $tile.parents('.cloned').length > 0) {
				continue;
			}

			impressions.push({
				name: typeof $tile.data('product-name') != 'undefined' ? $tile.data('product-name') + '' : '', // custom.title attribute in default locale
				id: typeof $tile.data('itemid') != 'undefined' ? $tile.data('itemid') + '' : '',
				price: $tile.data('price'),
				brand: $tile.data('brand'),
				category: $tile.data('category'),
				variant: $tile.data('variant'), // custom.colorDescription field in default locale
				list: catID,
				position: i + 1
			});
			$tile.data('gtm-processed', true);
		}

		return impressions;
	}

	function getImpressionsByArray(products, catID) {
		var impressions = [];

		for (var i = 0, l = products.length; i < l; i++) {
			var product = products[i];

			impressions.push({
				name: product.name,
				id: product.ID,
				price: product.price?.listPrices[0]?.value,
				brand: product.brand,
				category: product.category,
				variant: product.variant,
				list: catID,
				position: i + 1
			});
		}

		return impressions;
	}

	function onBannerLoad (push) {
		$document.ready(function(){
			var data = {
				'event': 'promoView',
				'ecommerce': {
					'promoView': {
						'promotions': []
					}
				}
			};

			$('.js-hp-gtm-banner-wrapper').each(function (i, el) {
				data.ecommerce.promoView.promotions.push(bannerHandler(i, el))
			});

			if (data.ecommerce.promoView.promotions.length) {
				push(data);
			}
		});

		$('.js-hp-gtm-banner-wrapper a').on('click', function () {
			var data = {
				'event': 'promoClick',
				'ecommerce': {
					'promoClick': {
						'promotions': []
					}
				}
			};

			var el = $(this).closest('.js-hp-gtm-banner-wrapper');
			var i = $('.js-hp-gtm-banner-wrapper').index(el);

			data.ecommerce.promoClick.promotions.push(bannerHandler(i, el));

			push(data);
		});

		function bannerHandler (index, banner) {
			var $banner = $(banner);
			var src = $banner.find('img').attr('src')
			if ( src ) {
				//trim query, hash if any
				src = src.replace(/[?#].*$/, '');
				//get file name from the uri
				src = src.match(/[^\/]*$/).pop();
				//trim file extension
				src = src.replace(/\.[^.]*$/, '');
			}
			else {
				src = '';
			}

			return {
				'id': $banner.data('asset-id'),
				'name': $banner.data('asset-name'),
				'creative': src, // name of the image file without the extension
				'position' : "slot" + (index + 1 )
			}
		}
	}

	var tagsOnPage = {
		"default" : [
			"section",
			"visitorStatus",
			"country",
			"language",
			"customerVisits",
			"customerLoyalty",
			"customerOngoingValue",
			"purchaseHistory",
			"pageType",
			"pageCategory",
			"loggedIn",
			"userID",
			"customerID",
			"customerValue",
			"ecommerce"

		],
		"plp" : [
			"catID",
			"page",
			"productSubCategory",
			"productCategory"
		],
		"product" : [
			"page",
			"productName",
			"productCategory",
			"productSubcategory",
			"productSku",
			"productPrice",
			"ecommerceStatus",
			"productType",
			"Product",
		],
		"checkout" : [
			"page",
			"cart",
			"ecommerceStatus"
		],
		"checkout_1" : [
			"page",
			"cart",
			"ecommerceStatus"
		],
		"checkout_2" : [
			"page",
			"cart",
			"ecommerceStatus"
		],
		"checkout_3" : [
			"page",
			"cart",
			"ecommerceStatus"
		],
		"confirmation" : [
			"stepNumber",
			"skulist",
			"qlist",
			"amlist",
			"orderID",
			"transactionId",
			"transactionCurrency",
			"transactionTotal",
			"transactionTax",
			"transactionShipping",
			"transactionShippingMethod",
			"transactionPaymentType",
			"revenue",
			"transactionProducts",
			"sampleName",
			"giftwrapPrice",
			"promoCodeName",
			"promoCodeValue",
			"accountCustomerId",
			"guestCustomerId",
			SUBSCRIPTION,
			"locationCity"
		]
	},
	eventsOnPage = {
		"default" : {
			"onAddToCart" : onAddToCart,
			"newsletterSubscriptionFast" : newsletterSubscriptionFast,
			"clickOnTumblr" : clickOnTumblr,
			"sendTofriend" : sendTofriend,
			"notifyMe" : notifyMe,
			"addToWishlist" : addToWishlist,
			"footerLinks" : footerLinks,
			"onClickSocialButton" : onClickSocialButton,
			"contactForm" : contactForm,
			"onQuickView" : onQuickView,
			"onProductTileClick" : onProductTileClick,
			"searchStoreLocator" : searchStoreLocator,
			"selectSizeClick" : selectSizeClick
		},
		"checkout" : {
			"onClickCheckout" : onClickCheckout,
			"onRemoveFormCart" : onRemoveFormCart,
			"onLoadCheckout" : onLoadCheckout
		},
		"checkout_1" : {
			"onClickCheckout" : onClickCheckout,
			"onRemoveFormCart" : onRemoveFormCart,
			"onLoadCheckout" : onLoadCheckout
		},
			plp:
		{
			onProductListLoad: onProductListLoad
		},
		"checkout_2" : {
			"onClickCheckout" : onClickCheckout,
			"onLoadCheckout" : onLoadCheckout
		},
		"checkout_3" : {
			"onClickCheckout" : onClickCheckout,
			"onLoadCheckout" : onLoadCheckout
		},
			search:
		{
			searchResult: searchResult,
			onProductListLoad: onProductListLoad
		},
		"product" : {
			onClickTab: onClickTab,
			onShopLookLoad: onShopLookLoad,
			onType3Load: onType3Load,
			onRecommendationsLoad: onRecommendationsLoad,
			onRecentlyViewedLoad: onRecentlyViewedLoad,
			onMoreColorLoad: onMoreColorLoad,
			onWearThisWithLoad: onWearThisWithLoad,
			onConfiguratorStartDesign: onConfiguratorStartDesign,
			onConfiguratorApplyElement: onConfiguratorApplyElement,
			onConfiguratorClose: onConfiguratorClose,
			onConfiguratorCloseConfirmed: onConfiguratorCloseConfirmed,
			onConfiguratorChangeSide: onConfiguratorChangeSide,
			onConfiguratorRestart: onConfiguratorRestart,
			onConfiguratorSave: onConfiguratorSave
		},
		"returns" : {
			"onReturns" : onReturns
		},
		"myaccount" : {
			"registerUser" : registerUser
		},
		"homepage" : {
			"onBannerLoad" : onBannerLoad,
		}
	};

	if (app.configs.gtm.enableGridViewChangeTracking) {
		eventsOnPage.search.onProductsGridView = onProductsGridView;
		eventsOnPage.plp.onProductsGridView = onProductsGridView;
	}

	app.gtm = app.gtm || {};
	app.gtm.config = app.gtm.config || {};
	app.gtm.config.global = {
		"getTags" : function () {
			return tagsOnPage;
		},
		"getEvents" : function () {
			return eventsOnPage;
		},
		getTileOrigin: getTileOrigin,
		setListInProductPageObj: setListInProductPageObj
	};
	app.gtm.reinitTileClickEvents = onProductTileClick;

}(window.app = window.app || {}, jQuery, document, hex_md5));
