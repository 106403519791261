class Director {
	constructor(builder) {
		this.builder = builder;
	}

	buildMinicartProductTile() {
		this.builder.setProductAdditionalClasses();
		this.builder.buildNameBlock();
		this.builder.buildImageBlockWithLink();
		this.builder.buildRemoveButton();
		this.builder.buildAddtoWishlistBlock();
		this.builder.buildAvailabilityBlock();
		this.builder.buildPriceBlock();
		this.builder.buildBadgesBlock();
		this.builder.buildSizeBlock();
		this.builder.buildQuantitySelectorBlock();
	}

	buildWishlistProductTile() {
		this.builder.setProductAdditionalClasses();
		this.builder.buildNameBlock('#wishlistProductTileName');
		this.builder.buildImageBlockWithLink();
		this.builder.buildPriceBlock();
		this.builder.buildWishlistAddToCartBlock();
		this.builder.buildRemoveWishlistButton();
		this.builder.buildLowInStockBlock();
		this.builder.buildBadgesBlock();
		this.builder.buildAvailabilityBlock();
	}

	buildSearchProductTile() {
		this.builder.buildPriceBlock();
		this.builder.buildImageBlockWithLink();
		this.builder.buildSliderBlock();
		this.builder.buildNameBlock();
		this.builder.buildBadgesBlock();
		this.builder.buildAddtoWishlistBlock();
		this.builder.buildColorAmountBlock();
		this.builder.buildVariationAttributesBlock();
	}
}

export default Director;
